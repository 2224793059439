import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditPortfolio from './EditPortfolio';
import EditWallet from './EditWallet';
import WalletCard from 'src/component/WalletCard';

const useStyles = makeStyles((theme) => ({
  sideBarMenu: {
    '& > *': {
      marginBottom: theme.spacing(0.5),
    },
    '& img.icons': {
      marginRight: '12px',
      width: '20px',
    },
    '& .subMenu': {
      marginTop: theme.spacing(2),
    },
  },
  iconColor: {
    '& img': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  },
  seeAllButtonPaper: {
    cursor: 'pointer', // Add cursor pointer for better UX
  },
}));

export default function Security({ userAddedWallets }) {
  const classes = useStyles();
  const history = useHistory(); // Use useHistory for programmatic navigation
  const [open, setOpen] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);

  useEffect(() => {
    console.log('Wallets from API response:', userAddedWallets);
  }, [userAddedWallets]);

  const toPascalCase = (str) => {
    if (!str) return ""; // Handle null or undefined gracefully
    return str
      .split(" ") // Split string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter, lowercase the rest
      .join(" "); // Join words back
  };

  const handleSeeAllClick = () => {
    history.push('/wallet'); // Programmatically navigate to the wallet page
  };

  return (
    <Box className={classes.sideBarMenu} >
      <Paper elevation={1} className="activeSide">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <img src="/images/logo_mobile.svg" alt="" className="icons" />
          <Typography variant="body2" color="primary">
            All Assets
          </Typography>
        </Box>
      </Paper>

      {userAddedWallets &&
        userAddedWallets.map((data, i) => {
          console.log("Wallet Data:", data); 
          const transformedData = {
            ...data,
            walletName: toPascalCase(data.walletName),
          };

          return <WalletCard classes={classes} data={transformedData} index={i} />;
        })}

      {/* "See All Wallets" as a clickable area */}
      <Paper
        elevation={1}
        className={classes.seeAllButtonPaper}
        onClick={handleSeeAllClick} // Navigate on click
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2" style={{color: '#83818E'}}>
            See All Wallets
          </Typography>
        </Box>
      </Paper>

      {open && <EditPortfolio open={open} setOpen={(item) => setOpen(item)} />}
      {openWallet && (
        <EditWallet
          open={openWallet}
          setOpenWallet={(item) => setOpenWallet(item)}
        />
      )}
    </Box>
  );
}
