import _ from "lodash";
import { colors, createTheme, List, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";
import { softShadows, strongShadows } from "./shadows";

const baseOptions = {
  typography,
  overrides: {
    MuiTableCell: {
      root: { padding: "4px 12px", borderBottom: "none" },
      head: { fontSize: "11px", borderBottom: "0px" },
      body: { height: 53, borderBottom: "0px" },
    },
    MuiCssBaseline: {
      "@global": {
        ul: {
         paddingBottom:"0px",
         paddingTop:"0px",
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: "#83818E",
      },
      sizeSmall: { padding: "5px" },
      root: {
        fontSize: "20px",
        padding: "11px",
        "@media (max-width: 767px)": {
          padding: "7px",
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: "17px 24px",
      },
    },
    MuiSvgIcon: {
      colorAction: { color: "#30B601" },
    },
    MuiListItemIcon: {
      root: { minWidth: "37px" },
    },
    MuiButton: {
      contained: {
        borderRadius: "8px",
        fontSize: "14px",
        padding: "8px 16px",
        boxShadow: "none",
      },
      containedSizeLarge: { padding: "7px 16px" },
      containedSecondary: { backgroundColor: "#1C1825" },
      containedPrimary: {
        color: "#fff !important",
        background: "linear-gradient(266.46deg, #5F51DE 0%, #AD5DD3 100%)",
      },
      text: {
        background: "transparent",
      },
    },

    MuiSelect: {
      iconOutlined: { top: "14px", right: "15px" },
      selectMenu: { height: "25px", padding: "7.5px 14px" },
      root: {
        // background: "#0F0E14",
        // border: "1px solid #7D56E7",
        borderRadius: "8px",
        height: "40px",
      },
      outlined: {
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
      },
    },
    MuiOutlinedInput: {
      root: {
        // height: "43px",
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7D56E7",
          },
        },
      },
      colorSecondary: {
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7D56E7",
          },
        },
      },
      colorPrimary: {
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7D56E7",
          },
        },
      },
      notchedOutline: {
        borderColor: "rgb(0 0 0 / 0.001)",
      },
      input: {
        padding: "13.5px 14px",
      },
    },

    MuiPaper: {
      rounded: {
        borderRadius: "8px",
      },
      elevation2: {
        padding: "15px",
      },
      elevation1: {
        padding: "15px",
        boxShadow: "none",
      },
    },
    MuiAvatar: {
      rounded: {
        background: "linear-gradient(266.46deg, #5F51DE 0%, #AD5DD3 100%)",
        borderRadius: "16px",
      },
    },
  },
};

const themesOptions = [
  {
    name: "DARK",

    overrides: {
      MuiBackdrop: { root: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
      MuiDivider: { root: { backgroundColor: "#4A4950", height: "2px" } },
      MuiStepLabel: {
        label: {
          color: "#546e7a",
          "&.MuiStepLabel-active": { color: "#ffffff" },
        },
      },
      MuiDropzoneArea: {
        root: {
          minHeight: "150px",
          backgroundColor: "#0F0E14",
        },
      },
      MuiListItem: {
        root: {
          background: "#1C1825",
          paddingTop:"0px"
        },
        button: {
          "&:hover": {
            backgroundColor: "rgb(17 14 24)",
          },
        },
      },
      MuiAutocomplete: {
        paper: { padding: "0px", backgroundColor: "#000" },
        popper: {
          padding: "0px",
        },
        option: { color: "#ccc", fontWeight: "600" },
      },
      MuiOutlinedInput: {
        root: {
          // background: "#0F0E14",
          // border: "1px solid #7D56E7",
          // borderRadius: "8px",
          // height: "40px",
          borderRadius: "8px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#7D56E7",
            },
          },
        },
        notchedOutline: {
          borderColor: "rgb(0 0 0 / 0.001)",
        },
      },
      MuiTableCell: {
        body: { color: "#FFFFFF", fontSize: "14px", background: "#1C1825" },
        head: {
          color: "#83818E",
          fontWeight: 500,
          background: "#1C1825",
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        },
      },
      
      MuiMenuItem: {
        root: { color: "#FFFFFF" },
      },
      MuiMenu: {
        paper: { boxShadow: "0px 4px 20px 0px #0F0E14;" },
      },
      MuiDialog: {
        paper: { backgroundColor: "#0F0E14" },
      },
      MuiInputBase: {
        root: {
          backgroundColor: "#1C1825",
          color: " #83818E",
          fontWeight: "600",
          fontSize: 14,
        },
        colorSecondary: { backgroundColor: "#0F0E14" },
      },
      MuiButtonBase: {
        root: {
          backgroundColor: "#1C1825",
        },
      },
      MuiListItemText: {
        root: { color: "#FFFFFF" },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: "#FFFFFF",
        },
      },
      MuiIconButton: {
        colorSecondary: {
          border: "1px solid  #4A4950",
          color: " #fff !important",
        },
      },
      MuiButton: {
        containedSecondary: {
          backgroundColor: "#1C1825 !important",
          color: "#83818E ",
        },
        outlinedSecondary: {
          backgroundColor: "#0F0E14",
          border: "0px",
          color: " #83818E !important",
        },
        outlinedPrimary: {
          backgroundColor: "transparent",
          border: "1px solid  #7D56E7",
          borderRadius: "8px",
          color: " #fff !important",
        },
        textPrimary: { color: " #fff !important" },
      },
      MuiPaper: {
        elevation4: { boxShadow: "0px 4px 20px 0px #0F0E14", padding: "15px" },
        elevation16: { backgroundColor: "#0F0E14", borderRadius: "0px" },
        root: {
          backgroundColor: "#1C1825",
          borderRadius: "16px",
        },
        outlined: {
          border: " 2px solid #7D56E7",
        },
       
      },
    },

    typography: {
      fontFamily: "'Manrope', helvetica",
      //fontWeight:"500px"
    },
    palette: {
      type: "dark",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        main: "#0F0E14",
        bg4box: "#1C1825",
        grey: "#1C1825",
        customButton: "#0F0E14",
        border: "#1C1825",
        borderAirdrop: "#4A4950",
        borderSocial: "#4A4950",
      },
      primary: {
        main: "#fff",
        reverse: "#fff",
        default: "#000000",
        top: "#000",
      },
      secondary: {
        main: "#83818E",
        default: "#898787",
      },
      default: {
        main: "#1C1825",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "LIGHT",
    overrides: {
      MuiBackdrop: { root: { backgroundColor: "rgba(0, 0, 0, 0.3)" } },
      MuiDivider: { root: { backgroundColor: "#eaeaea", height: "2px" } },
      MuiStepLabel: {
        label: {
          color: "#BDBCC3",
          "&.MuiStepLabel-active": { color: "#0F0E14" },
        },
      },
      MuiDropzoneArea: {
        root: {
          minHeight: "150px",
          backgroundColor: "#F7F7F8",
        },
      },
      MuiListItem: {
        root: {
          background: "#F7F7F8",
        },
        button: {
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        },
      },
      MuiSelect: {
        root: {
          // background: "#F7F7F8",
        },
      },
      MuiOutlinedInput: {
        root: {
          background: "#F7F7F8",
          // border: "1px solid #7D56E7",
          borderRadius: "8px",
          // height: "40px",
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#7D56E7",
            },
          },
        },
      },
      MuiAutocomplete: {
        paper: { padding: "0px", backgroundColor: "#ffffff" },
        popper: {},
        option: { color: "#000", fontWeight: "600" },
      },
      MuiTableCell: {
        body: { color: "#000000", fontSize: "14px", background: "#fff" },
        head: {
          color: "#000000",

          fontWeight: 300,
          background: "#fff",
        },
      },
      MuiMenuItem: {
        root: { color: "#000", background: "#fff" },
      },
      MuiMenu: {
        paper: { boxShadow: "0px 4px 20px 0px rgba(15, 14, 20, 0.15)" },
      },
      MuiDialog: {
        paper: { backgroundColor: "#fff" },
      },
      MuiInputBase: {
        root: {
          backgroundColor: "#fff",
          color: " #83818E",
          fontWeight: "600",
          fontSize: 14,
        },
        colorSecondary: { backgroundColor: "#f7f7f7" },
      },
      MuiButtonBase: {
        root: {
          backgroundColor: "#fff",
        },
      },
      MuiListItemText: {
        root: { color: "#0F0E14" },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: "#0F0E14",
        },
      },
      MuiIconButton: {
        colorSecondary: {
          border: "1px solid  #BDBCC3",
          color: " #fff !important",
        },
      },
      MuiButton: {
        containedSecondary: {
          color: "#83818E",
          backgroundColor: "#fff",
          "&:hover": { backgroundColor: "#ececec" },
        },
        outlinedSecondary: {
          backgroundColor: "#F7F7F8",
          border: "0px",
          color: " #83818E !important",
        },
        outlinedPrimary: {
          backgroundColor: "transparent",
          border: "1px solid  #7D56E7",
          borderRadius: "8px",
          color: " #000 !important",
        },
        textPrimary: { color: " #0F0E14 !important" },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: "0px 4px 20px 0px rgba(15, 14, 20, 0.15);",
          padding: "15px",
        },
        elevation16: { backgroundColor: "#0F0E14", borderRadius: "0px" },
        root: {
          //backgroundColor: "#fff",
          borderRadius: "16px",
        },
        outlined: {
          border: " 2px solid #7D56E7",
        },
      },
    },

    typography: {
      fontFamily: "'Manrope', helvetica",
    },
    palette: {
      type: "light",
      action: {},

      primary: {
        main: "#0F0E14",
        default: "#fff",
      },
      background: {
        main: "#F7F7F8",
        bg4box: "#fff",
        grey: "#fff",
        customButton: "#F7F7F8",
        border: "#E4E3E6",
        borderAirdrop: "#E4E3E6",
        borderSocial: "#BDBCC3",
      },
      secondary: {
        main: "#83818E",
        default: "#898787",
      },
      default: {
        main: "#F7F7F8",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createThemeWeb = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
