import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Container, Hidden } from "@material-ui/core";
import TopBar from "../DashboardLayout/TopBar/index";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.main,
    // display: "flex",
    minHeight: "100dvh",

    // overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    // overflow: "hidden",
    background: theme.palette.background.dark1,
  },
  Sidebar: { width: "240px" },
  content: {
    flex: "1 1 auto",
    // height: "calc(100% - 95px)",
    // overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "0px 0px 15px 10px ",
    "@media (max-width: 1280px)": {
      padding: "0px ",
      maxWidth:"0px"
    },
  },
}));

const ProfileDashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <div className={classes.wrapper}>
        <Container maxWidth="xl">
          <div className={classes.contentContainer}>
            <div className={classes.content} id="main-scroll">
              {children}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

ProfileDashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default ProfileDashboardLayout;
