import React, { useContext, useEffect,useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import { Refresh, Delete } from "@mui/icons-material";
import { AuthContext } from "src/context/Auth";
import { useTheme, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  walletContainer: {
    //backgroundColor: theme.name === "light" ? "#F7F7F8" : "#0F0E14",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  contentContainer: {
    //backgroundColor: theme.name === "light" ? "#FFF" : "#0F0E14",
    overflow: "hidden",
    minHeight: 960,
    width: "90%",
    margin: "auto",
    padding: "20px",
  },
  walletCard: {
    backgroundColor: theme.name === "LIGHT" ? "#FFF !important" : "#1C1825 !important",
    color:theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
    borderRadius: "12px !important",
    padding: "16px !important",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1) !important",
    fontFamily: '"Manrope", Helvetica, sans-serif !important',
  },
  textClr:{
    color:theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
  },
  walletHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  walletIcon: {
    width: "40px",
    height: "40px",
    backgroundColor: "#5A5A5A",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
  },
  walletDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  walletActions: {
    //color: theme.palette.text.secondary,
color:'white',  
},
  sectionTitle: {
    //color: theme.palette.text.secondary,
    fontSize: "14px",
  },
  sectionValue: {
    fontWeight: "bold",
    color: "inherit",
    fontSize: "20px",
  },
  footerText: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    marginTop: "16px",
  },
}));

const Wallets = () => {
  const theme = useTheme();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const userAddedWallets = auth?.userAddedWallets || [];
  const loading = auth?.isLoading || false;
  const [wallets, setWallets] = useState(auth?.userAddedWallets || []);
  //const loading = auth?.isLoading || false;

  useEffect(() => {
    console.log("Current Theme:", theme);
  }, [theme]);

  const handleDeleteWallet = (walletId) => {
    const updatedWallets = wallets.filter(
      (wallet) => wallet.walletId !== walletId
    );
    setWallets(updatedWallets);

    if (auth?.setUserAddedWallets) {
      auth.setUserAddedWallets(updatedWallets);
    }
  };
  // Delete handler
  // const handleDeleteWallet = (walletId) => {
  //   const updatedWallets = userAddedWallets.filter(
  //     (wallet) => wallet.walletId !== walletId
  //   );

  //   // Update the context or state
  //   if (auth?.setUserAddedWallets) {
  //     auth.setUserAddedWallets(updatedWallets); // Assuming context has a setter
  //   } else {
  //     console.error("setUserAddedWallets function is not available in AuthContext.");
  //   }

  //   // Optionally, log or notify the user
  //   console.log(`Wallet with ID ${walletId} deleted`);
  // };
  return (
    <Box className={classes.walletContainer}>
      <Box className={classes.contentContainer}>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
  <Typography className={classes.textClr}
  variant="h5"
  sx={(theme) => ({
    //color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000000", // White for dark, black for light
   // color:theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
    fontFamily: '"Manrope", "Helvetica", "Arial", sans-serif', // Font family
    fontWeight: 600, // Font weight
    textTransform: "none", // Ensures text is not capitalized
  })}
>
  Wallets
</Typography>
<Button
  variant="contained"
  component={Link} // Transform Button into a Link
  to="/add-assets" // Specify the page to navigate to
  sx={{
    background:
      "linear-gradient(180deg, rgb(95,81,222) 0%, rgb(173,93,211) 100%)",
    color: "#FFF",
    borderRadius: "12px",
    padding: "8px 16px",
    fontFamily: '"Manrope", Helvetica', // Example font family
    fontWeight: 600, // Example font weight
    textTransform: "none", 
  }}
>
  Add Wallet
</Button>

        </Box>

        {/* Content */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
            <CircularProgress color="inherit" />
          </Box>
        ) : userAddedWallets.length > 0 ? (
          <Grid container spacing={3}>
            {userAddedWallets.map((wallet, index) => (
              <Grid item xs={12} sm={6} md={4} key={wallet.walletId || index}>
                <Paper className={classes.walletCard}>
                  {/* Wallet Header */}
                  <Box className={classes.walletHeader}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                     <Box className={classes.walletIcon}>
                        <img
                           src="https://assets.coingecko.com/markets/images/61/small/kucoin.png?1706864282"
                           alt="KuCoin Icon"
                           style={{
                               width: "100%",
                               height: "100%",
                               objectFit: "contain", // Ensures the image fits within the box without cropping
                               borderRadius: "50%", // Optional: makes the image circular if the box is square
                            }}
                        />
                    </Box>
                      <Box>
                      <Typography variant="h6" sx={{ fontFamily: '"Manrope", Helvetica',fontWeight: 600,}}>
  {wallet.walletName
    ? wallet.walletName
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    : "Unnamed Wallet"}
</Typography>

<Typography
  variant="body2"
  sx={{
    color: '#83818E',
    fontFamily: '"Manrope", Helvetica',
    fontWeight: 600,
  }}
>
  {wallet.walletId ? `${wallet.walletId.slice(0, 20)}...` : "N/A"}
</Typography>

                      </Box>
                    </Box>
                    <Box>
                      <IconButton style={{color: '#83818E'}}>
                        <Refresh />
                      </IconButton>
                      <IconButton
                        style={{ color: "#83818E" }}
                        onClick={() => handleDeleteWallet(wallet.walletId)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Content Section */}
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                      <Typography style={{color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600}} className={classes.sectionTitle}>
                        Total Volume
                      </Typography>
                      <Typography className={classes.sectionValue}>
                        {wallet.totalVolume || "NA"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography style={{color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600}} className={classes.sectionTitle}>
                        Number of Transactions
                      </Typography>
                      <Typography className={classes.sectionValue}>
                        {wallet.numberOfTransactions || "NA"}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Footer */}
                  <Typography style={{ color: '#83818E', marginRight: '168px' }} className={classes.footerText}>
                    Updated {wallet.updatedTime || "NA"} min ago
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.primary,
              padding: 2,
            }}
          >
            No wallets added yet.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Wallets;