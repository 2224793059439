import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import HomeLayout from 'src/layouts/HomeLayout'
import LoginLayout from 'src/layouts/LoginLayout'
import Onboard from 'src/layouts/Onboard'
import DashboardLayout from 'src/layouts/DashboardLayout/Index'
import Assest from 'src/layouts/Assets/index'
import Social from 'src/layouts/Social/index'
import ProfileDashboardLayout from 'src/layouts/Profile/index'

export const routes = [
  {
    exact: true,
    path: '/',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home/LandingPage/Index')),
  },
  {
    exact: true,
    path: '/login',
    layout: LoginLayout,
    component: lazy(() => import('src/views/pages/Auth/Login/Index')),
  },
  {
    exact: true,
    path: '/get-started',
    layout: LoginLayout,
    component: lazy(() => import('src/views/pages/Auth/Login/GetStarted')),
  },
  {
    exact: true,
    path: '/signup',
    layout: LoginLayout,
    component: lazy(() => import('src/views/pages/Auth/Login/SignUp')),
  },
  {
    // guard: true,
    exact: true,
    path: '/verify-mail',
    layout: LoginLayout,
    component: lazy(() => import('src/views/pages/Auth/Login/VerifyMail')),
  },
  {
    exact: true,
    path: '/recover-password',
    layout: LoginLayout,
    component: lazy(() => import('src/views/pages/Auth/Login/RecoverPassword')),
  },
  {
    exact: true,
    path: '/recover-password-mail',
    layout: LoginLayout,
    component: lazy(() =>
      import('src/views/pages/Auth/Login/PasswordRecovery'),
    ),
  },
  {
    exact: true,
    path: '/create-new-password',
    layout: LoginLayout,
    component: lazy(() =>
      import('src/views/pages/Auth/Login/CreateNewPassword'),
    ),
  },
  {
    exact: true,
    path: '/select-country',
    layout: Onboard,
    component: lazy(() => import('src/views/pages/Auth/Login/Onboard/Index')),
  },
  {
    guard: true,
    exact: true,
    path: '/add-assets',
    layout: Assest,
    component: lazy(() => import('src/views/assest/index')),
  },
  {
    guard: true,
    exact: true,
    path: '/add-wallet',
    layout: Assest,
    component: lazy(() => import('src/views/assest/AddAssest')),
  },
  {
    guard: true,
    exact: true,
    path: '/portfolio',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Portfolio/Index')),
  },
  // {
  //   exact: true,
  //   path: '/vote',
  //   layout: DashboardLayout,
  //   component: lazy(() => import('src/views/pages/Portfolio/vote/index')),
  // },
  // {
  //   exact: true,
  //   path: '/voter-list',
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import('src/views/pages/Portfolio/vote/VoterListpage'),
  //   ),
  // },
  {
    exact: true,
    guard: true,
    path: '/profile',
    layout: ProfileDashboardLayout,
    component: lazy(() => import('src/views/pages/Profile/index')),
  },
 
  {
    exact: true,
    path: '/airdrop',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Airdrop/index')),
  },
  {
    exact: true,
    path: '/airdrop-details',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Airdrop/Detail/index')),
  },
  {
    exact: true,
    path: '/transaction',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Transaction/components/Index')),
  },
  {
    exact: true,
    path: '/wallet',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Wallet')),
  },
  // {
  //   exact: true,
  //   path: '/social',
  //   layout: Social,
  //   component: lazy(() => import('src/views/pages/Social/index')),
  // },
  // {
  //   exact: true,
  //   path: '/social-detail',
  //   layout: Social,
  //   component: lazy(() => import('src/views/pages/Social/Detail/index')),
  // },
  // {
  //   exact: true,
  //   path: '/ddd',
  //   layout: Assest,
  //   component: lazy(() => import('src/views/pages/Social/Detail/Slider')),
  // },
  {
    exact: true,
    path: '/performance',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Performance/Transactions')),
  },
  // {
  //   exact: true,
  //   path: '/dashboard',
  //   layout: Assest,
  //   component: lazy(() => import('src/views/pages/Dashboard/Index')),
  // },
  {
    exact: true,
    path: '/tax',
    layout: Assest,
    component: lazy(() => import('src/views/pages/Tax/index')),
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },

  {
    component: () => <Redirect to="/404" />,
  },
]
