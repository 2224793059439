import axios from 'axios'
import ApiConfig from '../config'
import { v4 as uuidv4 } from 'uuid'

const UUID = uuidv4()
//export const token = `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjaGlyYWdAbWFpbGluYXRvci5jb20iLCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXSwicm9sZSI6IlVTRVIiLCJ0d29mYXR5cGUiOiJOT05FIiwiYXV0aGVudGljYXRlZCI6dHJ1ZSwidXNlcklkIjozLCJ1c2VybmFtZSI6ImNoaXJhZ0BtYWlsaW5hdG9yLmNvbSIsImlhdCI6MTY4OTUxMDk5OSwiZXhwIjoxNjg5NTk3Mzk5fQ.zYWokx6ZW2cSUp6ToUF2p3ACwNnxvMJ8HuYqCSOQDmllFKI9MQE8awxeZzmtAO4mT3QbK-ddvDVNI0KXRWevBA`
export const token = `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwM2E2ODg3YWU3ZjNkMTAyNzNjNjRiMDU3ZTY1MzE1MWUyOTBiNzIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXByai1mcm9udGVuZC1hcHBzIiwiYXVkIjoiZGV2LXByai1mcm9udGVuZC1hcHBzIiwiYXV0aF90aW1lIjoxNzI3MTY1MDgxLCJ1c2VyX2lkIjoiOHByY2RjSHZuUlF6VlBNWTFHVDJiVUNrQUx1MiIsInN1YiI6IjhwcmNkY0h2blJRelZQTVkxR1QyYlVDa0FMdTIiLCJpYXQiOjE3MjcxNjUwODEsImV4cCI6MTcyNzE2ODY4MSwiZW1haWwiOiJtb3R1cnVrYWx5YW5AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbIm1vdHVydWthbHlhbkBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.fa4s-m2z9mVzVe9oIbNMeskMhiSyr-d3YN_zkSGGIerVmetcYaugLQILUgfeO0o_tQleWbucsTRru0yR_2MDSloCLHtWok0mVJO48NBUvu6sQqdj2RvY1vxcldeUHCSkM1UIZPPTjlTNdp_tbyJ5bsJTpzP6Pa3e71vjf_sourEGOK1bc7jk2P-t9I4nVIQY_IIMbBV9R54Bu3r67cpFbtqsF_2yh9WEoq5vrup3caqT6q31LaA5MHoEiN9qN5vfRiOCU5jA9dR1RhrN-WUqJlywIImGh5Y9TOSOQ6Y3mx3CvDknUmq2CNR9DOavydrpkRjdqhbZYiyUZN6A9FxY8A`
const headers = {
  'Content-Type': 'application/json',
  'X-REQUEST-DATE': new Date(),
  'X-CORRELATION-ID': UUID,
  'X-CLIENT-APP-ID': 'REACT_BETA',
}

export const postAPIHandler = async ({ endPoint, data, id }) => {
  try {
    return await axios({
      method: 'POST',
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        ...headers,
        authorization: window.localStorage.getItem('accessToken'),
      },
      data: data,
    })
  } catch (error) {
    console.log(error)
    return error
  }
}

export const getAPIHandler = async ({
  endPoint,
  id,
  source,
  token,
  paramsData,
}) => {
  try {
    return await axios({
      method: 'GET',
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        ...headers,
        authorization: token
          ? token
          : window.localStorage.getItem('accessToken'),
      },
      cancelToken: source ? source.token : null,
      params: paramsData ? paramsData : null,
    })
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const deleteAPIHandler = async ({ endPoint, id, userId }) => {
  try {
    return await axios({
      method: 'DELETE',
      url: `${ApiConfig[endPoint]}/${userId}/${id}`,
      headers: {
        ...headers,
        authorization: window.localStorage.getItem('accessToken'),
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export const putAPIHandler = async ({ endPoint, id, userId, data }) => {
  try {
    return await axios({
      method: 'PUT',
      url: `${ApiConfig[endPoint]}/${userId}/${id}`,
      headers: {
        ...headers,
        authorization: window.localStorage.getItem('accessToken'),
      },
      data: data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const rawAPICall = async ({ method, url }) => {
  try {
    return await axios({
      method: method,
      url: url,
      headers: {
        ...headers,
        authorization: window.localStorage.getItem('accessToken'),
      },
    })
  } catch (error) {
    console.log(error)
  }
}
