// const auth = useContext(AuthContext)
//   const { userData } = auth
//   const userId= userData?.uid

export const coinList = [
  {
    coinimg: "images/coinlist/bitcoin.svg",
    name: "Bitcoin",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/etherium.svg",
    name: "Ethereum",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/tether.svg",
    name: "USD Coin",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/dai.svg",
    name: "Litecoin",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
  {
    coinimg: "images/coinlist/anchor.svg",
    name: "Ripple",
    price: "$5000",
    quantity: "0.5",
    buyprice: "$50,000",
    usdvalue: "$25,000",
  },
];
export const apexData = [
  {
    coinane: "BTC",
    price: "45.00%",
    color: "#F6903D",
  },
  {
    coinane: "ETH",
    price: "25.00%",
    color: "#3DFFDC",
  },
  {
    coinane: "USDC",
    price: "15.00%",
    color: "#F26145",
  },
  {
    coinane: "LTC",
    price: "8.00%",
    color: "#268AFF",
  },
  {
    coinane: "LTC",
    price: "7.00%",
    color: "#5A3FFF",
  },
];
export const featureList = [
  {
    title: "Token",
    des: "Here is a description of this category ",
    num: "25",
  },
  {
    title: "DeFi",
    des: "Here is a description of this category ",
    num: "25",
  },
  {
    title: "NFT",
    des: "Here is a description of this category ",
    num: "25",
  },
  {
    title: "Chains",
    des: "Here is a description of this category ",
    num: "25",
  },
  {
    title: "CEX",
    des: "Here is a description of this category ",
    num: "25",
  },
  {
    title: "Features",
    des: "Here is a description of this category ",
    num: "25",
  },
];

export const postedData = [
  {
    title: "Improve Wallet Security ",
    des: "Implement additional security measures for better protection ...",
    coiname: "DeFi",
    status: "Completed",
    date: "04.30.23",
    profile: "User53",
    num: "20",
    color: "#30B601",
    status: "new",
  },
  {
    title: "Improve Wallet Security ",
    des: "Implement additional security measures for better protection ...",
    coiname: "DeFi",
    status: "Inprogress",
    date: "04.30.23",
    profile: "User54",
    num: "20",
    color: "#EDAC03",
    bgcolor: "#7D56E7",
    status: "completed",
  },
  {
    title: "Improve Wallet Security ",
    des: "Implement additional security measures for better protection ...",
    coiname: "DeFi",
    status: "Pending",
    date: "04.30.23",
    profile: "User53",
    num: "20",
    color: "#83818E",
    bgcolor: "#4A4950",
    status: "partial",
  },
  {
    title: "Improve Wallet Security ",
    des: "Implement additional security measures for better protection ...",
    coiname: "DeFi",
    status: "Completed",
    date: "04.30.23",
    profile: "User53",
    num: "20",
    color: "#30B601",
    status: "new",
  },
];
export const CoinList = [
  {
    coinname: "DeFi",
    des: "description of category",
  },
];
export const voterList = [
  {
    profileImg: "images/userprofile/profile.png",
    name: "Ralph Edwards",
    wallet: "0x2aBcDeFgHiJkLmN",
  },
  {
    profileImg: "images/userprofile/profile1.png",
    name: "Wade Warren",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile2.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile3.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile4.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile5.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile6.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
  {
    profileImg: "images/userprofile/profile7.png",
    name: "Esther Howard",
    wallet: "0x2aBcDeFgHiJkLmN34",
  },
];

export const defiList = [
  {
    defiDashboard: [
      {
        image: "images/defi/anchor.svg",
        networkname: "All Networks",
        price: "$10,786,000",
      },
      {
        image: "images/defi/avalanche.png",
        networkname: "Solana",
        price: "$987",
        prcent: "10%",
      },
      {
        image: "images/defi/binance.png",
        networkname: "Uniswap",
        price: "$2312",
        prcent: "10%",
      },
      {
        image: "images/defi/cosmos.png",
        networkname: "Cosmos",
        price: "$3535",
        prcent: "10%",
      },
      {
        image: "images/defi/polygon.svg",
        networkname: "BNB Chain",
        price: "$3434",
        prcent: "10%",
      },
      {
        image: "images/defi/portfoliotracker.svg",
        networkname: "Avalanche",
        price: "$545",
        prcent: "10%",
      },
      {
        image: "images/defi/solana.png",
        networkname: "Polygon",
        price: "$5445",
        prcent: "10%",
      },
      {
        image: "images/defi/uniswap.png",
        networkname: "Anchor",
        price: "$4545",
        prcent: "10%",
      },
      {
        image: "images/coinlist/dai.svg",
        networkname: "Dai",
        price: "$545",
        prcent: "10%",
      },
    ],
  },
  {
    holdingList: [
      {
        image: "images/coinlist/dai.svg",
        coinname: "DAI",
        symbol: "DAI",
        network: "BNB Chain",
        price: "$4545",
        quantity: "12133",
        value: "324324",
        change: "435345",
        profit: "true",
      },
      {
        image: "images/defi/uniswap.png",
        coinname: "Uniswap",
        symbol: "UNI",
        network: "Etherium",
        price: "$4545",
        quantity: "12133",
        value: "324324",
        change: "435345",
        profit: "true",
      },
      {
        image: "images/coinlist/dai.svg",
        coinname: "DAI",
        symbol: "DAI",
        network: "BNB Chain",
        price: "$4545",
        quantity: "12133",
        value: "324324",
        change: "435345",
      },
    ],
  },
  {
    stakingList: [
      {
        coinimgae: "images/defi/btc.svg",
        coinname1: "WBTC",
        coinname2: "ETH",
        balance: "0.00 WBTC",
        balance1: "2,590 ETH",
        reward: "0.13 WBTC($2,180)",
        reward1: "1.78 ETH($2,227)",
        value: "$3248,45",
      },
      {
        coinimgae: "images/defi/btc.svg",
        coinname1: "WBTC",
        coinname2: "ETH",
        balance: "0.00 WBTC",
        balance1: "2,590 ETH",
        reward: "0.13 WBTC($2,180)",
        reward1: "1.78 ETH($2,227)",
        value: "$3248,45",
      },
    ],
  },
  {
    dashData: [
      {
        boxName: "Current Balance",
        value: "$502,606.01",
        change: "4.52",
        profit: "true",
      },
      // {
      //   boxName: "Unrealized Gains",
      //   value: "$520,261.37",
      //   change: "2.14",
      //   profit: "false",
      // },
      // {
      //   boxName: "Best Performer",
      //   value: "Bitcoin",
      //   change: "10.72",
      //   profit: "true",
      //   icon: "images/coinlist/bitcoin.svg",
      // },
    ],
  },
  {
    nftData: [
      {
        nftImg: "images/nft/nft1.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "loss",
      },
      {
        nftImg: "images/nft/nft2.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "profit",
      },
      {
        nftImg: "images/nft/nft3.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "profit",
      },
      {
        nftImg: "images/nft/nft4.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "profit",
      },
      {
        nftImg: "images/nft/nft5.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "loss",
      },
      {
        nftImg: "images/nft/nft6.png",
        name: "Makimono #454",
        price: "0,0386",
        fprice: "0,0386",
        change: "4.52",
        pl: "profit",
      },
      // Add more NFT data objects as needed
    ],
  },
  {
    airdrop: [
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Ongoing",
      },
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop2.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Completed",
      },
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop3.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Unclaimed",
      },
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Ongoing",
      },
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop2.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Completed",
      },
      {
        nftImg: "images/bgairdrop.png",
        dropImg: "/images/nft/airdrop3.png",
        name: "Makimono #454",
        price: "2500",
        fprice: "386",
        change: "4.52",
        pl: "loss",
        process: "Unclaimed",
      },

      // Add more NFT data objects as needed
    ],
  },
  {
    handpick: [
      {
        newsImg: "images/news/news1.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news2.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news3.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news4.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news5.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news6.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news7.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news8.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news4.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },

      // Add more news data objects as needed
    ],
  },
  {
    trending: [
      {
        newsImg: "images/news/news1.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news2.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news3.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },

      // Add more news data objects as needed
    ],
  },
  {
    upcoming: [
      {
        newsImg: "images/news/news1.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news2.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },
      {
        newsImg: "images/news/news3.png",
        time: "6 min read",
        date: "Nov 3, 2021",
        name: "The Rise of NFTs: Unlocking the Potential of Digital Assets",
        description:
          "The world of digital art and collectibles has undergone a revolutionary transformation with the advent of Non-Fungible Tokens (NFTs) collectibles has undergone a revolutionary.",
        author: "Jacob Jones",
        position: "Marketer ",
      },

      // Add more news data objects as needed
    ],
  },
];

// export const historyList = [
//   {
//     date: "2022/12/04 09:10:47",
//     hsh: "08cd3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     status: "Receive",
//     rehsh: "4d7308cd3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     value: "+ $10,000.00 DAI",
//     color: "#30B601",
//     fee: "0.00ETH",
//     fee1: "($0.73)",
//     image: "images/defi/receive.svg",
//     images1: "images/defi/binance.png",
//   },
//   {
//     date: "2022/12/04 09:10:47",
//     hsh: "24d7308cd3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     status: "Remove",
//     rehsh: "d3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     value: "+ $10,000.00 DAI",
//     color: "#E74238",
//     fee: "0.00ETH",
//     fee1: "($0.73)",
//     image: "images/defi/remove.svg",
//     images1: "images/defi/binance.png",
//   },
//   {
//     date: "2022/12/04 09:10:47",
//     hsh: "9824d7308cd3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     status: "Contract Interaction",
//     rehsh: "308cd3f7fc5d6fdb5d7eaa0fb2b871a6e5111d8",
//     value: "+ $10,000.00 DAI",
//     color: "#E74238",
//     fee: "0.00ETH",
//     fee1: "($0.73)",
//     image: "images/defi/swap.png",
//     images1: "images/defi/binance.png",
//     images2: "images/defi/tsnimg.svg",
//     nft: "+ 1 NFT Caked Apes #6565",
//   },
// ];
